@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --dialog: 0 0% 100%;
    --dialog-foreground: 240 10% 3.9%;

    --primary: 163 82% 36%;
    --primary-foreground: 0 0% 98%;

    --secondary: 263 90% 51%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 5% 35%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --frozen: 240 5.9% 90%;

    --radius: 0rem;

    --swiper-theme-color: #fafafa;

    --swiper-navigation-size: calc(1rem);
    --swiper-navigation-sides-offset: 0;

    --swiper-pagination-color: #fafafa;
    --swiper-pagination-right: 0.5rem;
    --swiper-pagination-bottom: 0.5rem;
    --swiper-pagination-bullet-size: 0.5rem;
    --swiper-pagination-bullet-border-radius: 50%;
    --swiper-pagination-bullet-inactive-color: #1e2229;
    --swiper-pagination-bullet-inactive-opacity: 0.6;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 0.25rem;
    --swiper-pagination-bullet-vertical-gap: 0.5rem;
  }

  .dark {
    --background: 0 0% 9%;
    --foreground: 0 0% 98%;

    --card: 218 15% 12%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 6%;
    --popover-foreground: 0 0% 98%;

    --dialog: 0 0% 6%;
    --dialog-foreground: 0 0% 98%;

    --primary: 186 66% 72%;
    --primary-foreground: 0 0% 9%;

    --secondary: 39 100% 68%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 14%;
    --muted-foreground: 165 2% 66%;

    --accent: 0 0% 6%;
    --accent-foreground: 0 0% 98%;

    --destructive: 348 85% 54%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14%;
    --input: 0 0% 16%;
    --ring: 0 0% 98%;
    --frozen: 0 0% 14%;

    --swiper-navigation-color: var(--muted);
    --swiper-theme-color: var(--primary);
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background font-body tracking-wide text-foreground;
  }
}
